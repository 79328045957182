import React, { lazy } from "react";
const EditMagazinePage = lazy(() =>
  import("./pages/magazine/component/EditMagazinePage")
);
const AccountStatus = lazy(() => import("./pages/marriage/AccountStatus"));

const ExecutiveMemberBal = lazy(() =>
  import("./pages/excecutive/ExecutiveMemberBal")
);
const EditCover = lazy(() => import("./pages/magazine/component/EditCover"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const VerifiedMarriage = lazy(() =>
  import("./pages/marriage/VerifiedMarriage")
);
const UnverifiedMarriage = lazy(() =>
  import("./pages/marriage/UnverifiedMarriage")
);
const CreateMarriage = lazy(() => import("./pages/marriage/CreateMarriage"));
const DeactivatedMarriage = lazy(() =>
  import("./pages/marriage/DeactivatedMarriage")
);
const NewContact = lazy(() => import("./pages/phonebook/NewContact"));
const VerifiedContact = lazy(() => import("./pages/phonebook/VerifiedContact"));
const UnverifiedContact = lazy(() =>
  import("./pages/phonebook/UnverifiedContact")
);
const DeactivatedContact = lazy(() =>
  import("./pages/phonebook/DeactivatedContact")
);
const CreateNews = lazy(() => import("./pages/news/CreateNews"));
const DraftNews = lazy(() => import("./pages/news/DraftNews"));
const UnpublishedMarriage = lazy(() =>
  import("./pages/marriage/UnpublishedMarriage")
);
const AddCover = lazy(() => import("./pages/magazine/AddCover"));
const ManageCover = lazy(() => import("./pages/magazine/ManageCover"));
const NewRegistration = lazy(() => import("./pages/magazine/NewRegistration"));
const PaidMembers = lazy(() => import("./pages/magazine/PaidMembers"));
const DeactivateMember = lazy(() =>
  import("./pages/magazine/DeactivateMember")
);
const RegistrationForm = lazy(() =>
  import("./pages/excecutive/RegistrationForm")
);
const ExecutiveRequest = lazy(() =>
  import("./pages/excecutive/ExecutiveRequest")
);
const VertifiedExecutive = lazy(() =>
  import("./pages/excecutive/VertifiedExecutive")
);
const ExecutiveWorkStatus = lazy(() =>
  import("./pages/excecutive/ExecutiveWorkStatus")
);
const DeactiveExecutive = lazy(() =>
  import("./pages/excecutive/DeactiveExecutive")
);
const ExecutiveDiscount = lazy(() =>
  import("./pages/excecutive/ExecutiveDiscount")
);
const MarriageDetails = lazy(() =>
  import("./pages/marriage/component/MarriageDetails")
);
const EditMarriage = lazy(() =>
  import("./pages/marriage/component/EditMarriage")
);
const AutoMatch = lazy(() => import("./pages/marriage/component/AutoMatch"));
const Paididplan = lazy(() => import("./pages/marriage/component/Paididplan"));

const EditPhonebookContact = lazy(() =>
  import("./pages/phonebook/component/EditPhonebookContact")
);
const EditNews = lazy(() => import("./pages/news/EditDraftNews"));
const NextEvent = lazy(() => import("./pages/news/NextEvent"));
const PublishNews = lazy(() => import("./pages/news/PublishNews"));
const EditProfile = lazy(() => import("./pages/excecutive/EditProfile"));
const MagazinePages = lazy(() =>
  import("./pages/magazine/component/MagazinePages")
);
const AddMagazinePage = lazy(() =>
  import("./pages/magazine/component/AddMagazinePage")
);
const SetPagesOrder = lazy(() =>
  import("./pages/magazine/component/SetPagesOrder")
);
// const ExecutiveLogin = lazy(() => import("./pages/ExecutiveLogin"));

const routes = [
  { path: "/", exact: true, name: "Dashboard", element: Dashboard },
  {
    path: "/unverified-marriage",
    name: "Unverified Marriage",
    element: UnverifiedMarriage,
  },
  {
    path: "/marrige-details",
    name: "Marriage Details",
    element: MarriageDetails,
  },
  {
    path: "/verified-marriage",
    name: "Verified Marriage",
    element: VerifiedMarriage,
  },
  {
    path: "/create-marriage",
    name: "Create Marriage",
    element: CreateMarriage,
  },
  {
    path: "/deactivated-marriage",
    name: "Deactivated Marriage",
    element: DeactivatedMarriage,
  },
  {
    path: "/unpublished-marriage",
    name: "Unpublished Marriage",
    element: UnpublishedMarriage,
  },
  {
    path: "/edit-marriage",
    name: "Edit Marriage",
    element: EditMarriage,
  },
  {
    path: "/new-contact",
    name: "New Contact",
    element: NewContact,
  },
  {
    path: "/verified-contact",
    name: "Verified Contact",
    element: VerifiedContact,
  },
  {
    path: "/unverified-contact",
    name: "Unverified Contact",
    element: UnverifiedContact,
  },
  {
    path: "/deactivated-contact",
    name: "Deactivated Contact",
    element: DeactivatedContact,
  },
  {
    path: "/create-news",
    name: "Create News",
    element: CreateNews,
  },
  {
    path: "/draft-news",
    name: "Draft News",
    element: DraftNews,
  },
  {
    path: "/edit-draft-news",
    name: "Edit News",
    element: EditNews,
  },
  {
    path: "/next-event",
    name: "Next Event",
    element: NextEvent,
  },
  {
    path: "/publish-news",
    name: "Publish News",
    element: PublishNews,
  },
  {
    path: "/add-cover",
    name: "Add Cover",
    element: AddCover,
  },
  {
    path: "/manage-cover",
    name: "Manage Cover",
    element: ManageCover,
  },
  {
    path: "/new-magazine-registration",
    name: "New Registration",
    element: NewRegistration,
  },
  {
    path: "/paid-member",
    name: "Paid Member",
    element: PaidMembers,
  },
  {
    path: "/deactivated-member",
    name: "Deactivated Member",
    element: DeactivateMember,
  },
  {
    path: "/executive-registration",
    name: "Registration Form",
    element: RegistrationForm,
  },
  {
    path: "/executive-request",
    name: "New Request",
    element: ExecutiveRequest,
  },
  {
    path: "/verified-executive",
    name: "Verified Executive",
    element: VertifiedExecutive,
  },
  {
    path: "/work-status",
    name: "Work Status",
    element: ExecutiveWorkStatus,
  },
  {
    path: "/deactivated-executive",
    name: "Deactivated Executive",
    element: DeactiveExecutive,
  },
  {
    path: "/executive-discount",
    name: "Executive Discount",
    element: ExecutiveDiscount,
  },
  {
    path: "/auto-match",
    name: "Auto Match",
    element: AutoMatch,
  },
  {
    path: "/edit-contact",
    name: "Edit Contact",
    element: EditPhonebookContact,
  },
  {
    path: "/edit-profile",
    name: "Edit profile",
    element: EditProfile,
  },

  {
    path: "/executive-member-balance",
    name: "Executive Member Balance",
    element: ExecutiveMemberBal,
  },
  {
    path: "/account-status",
    name: "Candidate Account Status",
    element: AccountStatus,
  },
  {
    path: "/edit-cover-page",
    name: "Edit Cover Page",
    element: EditCover,
  },
  {
    path: "/magazine-pages",
    name: "Magazine Page",
    element: MagazinePages,
  },
  {
    path: "/edit-magazine-page",
    name: "Edit Magazine Page",
    element: EditMagazinePage,
  },
  {
    path: "/add-magazine-pages",
    name: "Add Magazine Page",
    element: AddMagazinePage,
  },
  {
    path: "/set-pages-order",
    name: "Set Pages Order",
    element: SetPagesOrder,
  },
  {
    path: "/paididplan/:regid",
    name: "Paid Id Plan",
    element: Paididplan,
  },
];

export default routes;
