import React, { useEffect, useState } from "react";
import { Link, useOutlet } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  Container,
  Header,
  Sidebar,
  Sidenav,
  Content,
  Nav,
  Dropdown,
} from "rsuite";
import { nav } from "../data/nav";
import { HiUserCircle } from "react-icons/hi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import AppContent from "./AppContent";
import { FaBars } from "react-icons/fa";
import api from "../lib/api";
import { useQuery } from "@tanstack/react-query";
import { imageUrl, imageUrl2  } from "../lib/api";


const ProtectedLayout = () => {
  const [expand, setExpand] = useState(false);
  const [mobile, setMobile] = useState(false);
  const { user, logout } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;
  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (index) => {
    setActiveMenu(index);
  };

  const { data: requestCount } = useQuery(
    ["request-count"],
    () => api.get(`/admin/executive/executive-request-count`),
    {
      select: (res) => res.data.data,
    }
  );

  const handleResize = () => {
    if (window.innerWidth < 920) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 920) {
      setMobile(true);
    }
    const handleResizeWrapper = () => {
      handleResize();
    };
    window.addEventListener("resize", handleResizeWrapper);

    return () => {
      window.removeEventListener("resize", handleResizeWrapper);
    };
  }, []);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Container>
      <Sidebar
        style={{ display: "flex", flexDirection: "column" }}
        width={expand ? 260 : mobile ? 0 : 56}
        collapsible
        onMouseOver={() => {
          setExpand(true);
        }}
        onMouseOut={() => {
          setExpand(false);
        }}
      >
        <Sidenav.Header>
          <span>
            {expand ? (
              <span onClick={() => setExpand(false)}>
                <img
                   src={`${imageUrl2}/kushprakash-logo.png`} // Corrected line
                  width="200px"
                  height="50px"
                  alt=""
                />
                <IoMdArrowRoundBack height="50px" width="50px" />
              </span>
            ) : (
              <span onClick={() => setExpand(true)}>
                <IoMenu />
              </span>
            )}
          </span>
        </Sidenav.Header>
        <Sidenav expanded={expand} appearance="subtle">
          <Sidenav.Body>
            <Nav>
              {nav.map((item, i) => {
                if (item.menu) {
                  return (
                    <Nav.Menu
                      key={i}
                      eventKey={i}
                      title={item.title}
                      icon={item.icon}
                      placement="rightStart"
                      expanded={activeMenu === i}
                      onSelect={() => handleMenuClick(i)}
                    >
                      {item.menu.map((item2, j) => (
                        <Nav.Item key={j}>
                          <Link to={item2.link}>
                            {item2.title}
                            {item2.title === "New Request" &&
                              requestCount &&
                              `(${requestCount[0]?.count})`}
                          </Link>
                        </Nav.Item>
                      ))}
                    </Nav.Menu>
                  );
                } else {
                  return (
                    <Nav.Item key={i} icon={item.icon}>
                      <Link to={item.link}> {item.title}</Link>
                    </Nav.Item>
                  );
                }
              })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>

      <Container>
        <Header>
          <FaBars
            size={30}
            onClick={() => setExpand(true)}
            className="sideToggle"
          />
          <h4>{pathname.split("/")[1].toUpperCase().replaceAll("-", " ")}</h4>
          <Dropdown
            trigger={["hover"]}
            title={<HiUserCircle />}
            placement="bottomEnd"
          >
            <Dropdown.Item>Profile</Dropdown.Item>
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown>
        </Header>
        <Content className="p-2">
          <AppContent />
        </Content>
        <div className="copyrightSec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Copyright &copy; 2022. All Rights Reserved. Developed By{" "}
                  <a href="https://www.oriolinfotech.com/" target="_blank">
                    Oriol Infotech Solutions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default React.memo(ProtectedLayout);
