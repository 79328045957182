import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import "react-tabs/style/react-tabs.css";
import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import ProtectedLayout from "./components/ProtectedLayout";
import HomeLayout from "./components/HomeLayout";
import { Loader } from "rsuite";
import ExecutiveLogin from "./pages/ExecutiveLogin";

const loading = (
  <div className="fullLoader">
    <Loader />
  </div>
);

const Login = lazy(() => import("./pages/Login"));

function App() {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/executive-login" element={<ExecutiveLogin />} />
        </Route>
        <Route path="*" element={<ProtectedLayout />} />
      </Routes>
    </Suspense>
  );
}

export default App;
